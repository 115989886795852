<template>
  <div class="pa-2">
    <h1 class="headline mb-1 font-weight-bold">
      エスネットワークス様ご協賛開始についてのお知らせ
    </h1>
    <p class="body-1">
      2022年8月1日
    </p><a
      class="esnet-banner"
      href="https://recruit.esnet.co.jp/person/sato/"
    ><img
      class="mt-3 mx-6 mb-5"
      :src="imageSrc"
      eager="eager"
      contain="contain"
      max-width="auto"
      width="90%"
    ></a>
    <h2 class="title mb-2 font-weight-black">
      ご協賛開始のお知らせ
    </h2>
    <p class="body-1 mb-1">
      会計士試験過去問コム（以下、本サイト）は、公認会計士受験生のために過去問を無料で解くことのできるWebアプリ、統計情報などを提供しております。
    </p>
    <p class="body-1 mb-1">
      本サイトは、主として個人の公認会計士が運営をしていますが、公認会計士受験生の試験勉強や受験情報の確保の充実に資することを目的としています。
    </p>
    <p class="body-1 mb-1">
      この度、本サイトは「エスネットワークス様」様のご厚意により、協賛をいただくこととなりましたので、お知らせします。
    </p>
    <p class="body-1 mb-1">
      エスネットワークス様は経営者視点で企業の変革を支援する会計・財務のプロフェッショナル集団です。会計士試験合格者が多く活躍している経営コンサルティングファームです。
    </p>
  </div>
</template>

<script>
export default {
  name: "NewsBdo",
  computed: {
    imageSrc() {
      return require('@/assets/esnet/banner.png')
    }
  }
}
</script>

<style scoped>

</style>
